/**
 * Detect the current active responsive breakpoint in Bootstrap
 * @returns {string}
 */
function getResponsiveBreakpoint() {
  var envs = {
    xs: 'd-none',
    sm: 'd-sm-none',
    md: 'd-md-none',
    lg: 'd-lg-none',
    xl: 'd-xl-none'
  };
  var env = '';

  var $el = $('<div>');
  $el.appendTo($('body'));

  for (var i = Object.keys(envs).length - 1; i >= 0; i--) {
    env = Object.keys(envs)[i];
    $el.addClass(envs[env]);
    if ($el.is(':hidden')) {
      break; // env detected
    }
  }
  $el.remove();
  return env;
}

/**
 * Adds sidebar to current menu item on smaller screen Tablets and Mobile
 * @returns {void}
 */
function setSubMenu() {
//   alert('dfdfd');
  if ($('.navbar-main-links .nav-item.active').length <= 0) {
    console.log('add sub');
    return false;
  }

  var target = 'nav.navbar-main .navbar-main-links > .active',
    sidebar_nav = $('#navbarAsideContent .navbar-aside')
      .parent()
      .html(),
    dropdown = $('<div>', {
      class: 'dropdown-menu show',
      'aria-labelledby': 'navbarDropdown',
      html: sidebar_nav
    });

  if ($.inArray(getResponsiveBreakpoint(), ['md', 'sm', 'xs']) !== -1) {
    if (!$(target).hasClass('mobified')) {
      dropdown.appendTo(target);
      $(target).addClass('dropdown show mobified');
      $(target + ' > a')
        .addClass('dropdown-toggle')
        .attr('data-toggle', 'dropdown');
    }
  } else {
    $(target + '.nav-item .dropdown-menu').remove();
    $(target).removeClass('dropdown show mobified');
    $(target + ' a')
      .removeClass('dropdown-toggle')
      .attr('data-toggle', '');
  }
}
